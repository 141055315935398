import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'

export function extractLinkInfo(obj) {
  if (!obj) return {}

  if (Array.isArray(obj)) return extractLinkInfo(obj[0])

  if (obj._type === 'internalLink') return {
    href: obj.ref ? determineDocumentPathSync({ document: obj.ref, routeMap }) : null,
    label: obj.label ?? obj.title,
    target: obj.openInNewTab ? '_blank' : undefined
  }

  if (obj._type === 'externalLink') return obj

  return {}
}
