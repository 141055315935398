import styles from './Heading.css'

export function HeadingXl({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentXl, styles.title, layoutClassName)} {...{ h, title }} />
  )
}

export function HeadingLg({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentLg, styles.title, layoutClassName)} {...{ h, title }} />
  )
}

export function HeadingMd({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentMd, styles.title, layoutClassName)} {...{ h, title }} />
  )
}

export function HeadingSm({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentSm, styles.title, layoutClassName)} {...{ h, title }} />
  )
}

function HeadingBase({ title, h, className }) {
  /** @type {any} */
  const HBase = `h${h}`

  return (
    <HBase className={cx(styles.componentBase, className)}>{title}</HBase>
  )
}
