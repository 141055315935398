import { useElementSize }  from '@kaliber/use-element-size'
import { dataLayerEvent } from '/machinery/tracking/dataLayerEvent'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { useSpring, animated } from 'react-spring'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'
import iconChevronRight from '/images/icons/chevron-right.raw.svg'

import styles from './Faq.css'

export function Faq({  questions, title, link }) {
  const [active, setActive] = React.useState(null)
  const { href, label } = extractLinkInfo(link)

  return (
    <div className={styles.component}>
      {title && <HeadingMd layoutClassName={styles.heading} h={2} {...{ title }} />}
      <div className={styles.items}>
        {questions && questions.map(x => (
          <Item
            onActiveChange={() => handleActiveChangeForItem(x)}
            active={active === x}
            key={x._key}
            id={x._key}
            question={x.question}
            answer={x.answer}
          />
        ))}
      </div>
      {link && (
        <a {...{ href }} className={styles.button} data-x='link'>
          {label}
          <Icon layoutClassName={styles.icon} icon={iconChevronRight} />
        </a>
      )}
    </div>
  )

  function handleActiveChangeForItem(item) {
    const newActive = active === item ? null : item
    setActive(newActive)
    dataLayerEvent('interaction_clicked', {
      interaction: {
        title: 'Faq',
        type: 'click',
        subject: item.question,
        action: active === item ? 'close' : 'open'
      }
    })
  }
}
function Item({ id, question, answer, active, onActiveChange }) {
  const { size: { height }, ref: elementRef } = useElementSize()

  const answerAnimation = useSpring({
    height: active ? height : 0,
    opacity: active ? 1 : 0,
    config: { tension: 300, friction: active ? 10 : 50 }
  })

  return (
    <div className={cx(styles.componentItem, active && styles.active)}>
      <h3 className={styles.question}>
        <button
          type='button'
          className={styles.trigger}
          onClick={onActiveChange}
          data-x='toggle-faq-item'
          aria-controls={id}
          aria-expanded={active}
        >
          <span>{question}</span>
          <Icon layoutClassName={cx(styles.icon, active && styles.rotate)} icon={iconChevronDown} />
        </button>
      </h3>

      <animated.div
        className={styles.answer}
        style={answerAnimation}
        aria-hidden={!active}
        {...{ id }}
      >
        <div ref={elementRef}>
          <div className={styles.inner}>{answer}</div>
        </div>
      </animated.div>
    </div>
  )
}
